import Barba from 'barba.js';

export default function BarbaBasic(){
  

  // hashリンクを活かす
  const registarHashLink = ()=>{
    let elm = document.querySelectorAll('a');
    let homeURL = location.protocol + '//' + location.host;
    let pageURL =  homeURL + location.pathname;
    for(let i = 0; i < elm.length; i++){
      let extract_hash = elm[i].href.replace(/#.*$/,"");
      if(elm[i].href.startsWith(homeURL)){
        if(elm[i].href.indexOf('#') > -1 && extract_hash != pageURL){
          return true;
        }
      }
    }
  }
  registarHashLink();


  // rewrite head tag
  Barba.Dispatcher.on('newPageReady', (currentStatus, prevStatus, container, newPageRawHTML)=>{
    let head = document.head;
    let newPageRawHead = newPageRawHTML.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
    let newPageHead = document.createElement('head');
    newPageHead.innerHTML = newPageRawHead;

    let removeHeadTags = [
      "meta[name='keywords']",
      "meta[name='description']",
      "meta[property^='og']",
      "meta[name^='twitter']",
      "meta[itemprop]",
      "link[itemprop]","link[rel='prev']",
      "link[rel='next']",
      "link[rel='canonical']"
    ].join(',');

    let headTags = head.querySelectorAll(removeHeadTags);
    for(let i = 0; i < headTags.length; i++){
      head.removeChild(headTags[i]);
    }
    let newHeadTags = newPageHead.querySelectorAll(removeHeadTags);
    for(var i = 0; i < newHeadTags.length; i++ ){
      head.appendChild(newHeadTags[i]);
    }
  });


  //GoogleAnalytics
  Barba.Dispatcher.on('initStateChange', function() {
    if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
      ga('send', 'pageview', location.pathname);
    }
    if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
      gtag('config', 'UA-106833161-5', {'page_path': location.pathname}); 
    }
  });

}