export default function homeUtil(){
  let aspect = window.innerWidth / window.innerHeight;
  let video = document.querySelector('.home-intro > video');

  const resize = ()=>{
    if(aspect < 16 / 9){
      video.style.height = window.innerHeight + 100 + 'px';
      video.style.width = 'auto';
    }else{
      video.style.width = window.innerWidth + 'px';
      video.style.height = 'auto';
    }
  }

  const init = ()=>{
    resize();
    video.classList.add('active');
  }
  init();

  window.addEventListener('resize', resize, false);

}