// vendor
import Barba from 'barba.js';
import imagesLoaded  from 'imagesloaded';
import BarbaBasic from './modules/barba-basic';

// util
import ToggleNav from './modules/util/toggle-nav';
import AddHover from './modules/util/addhover';
// import pageTop from './modules/util/pagetop';
import fullHeight from './modules/util/fullheight';

// page
import homeUtil from './modules/site/home';


import gMapDisplay from './modules/map';
import SlideShow from './modules/slider/slideshow';

// import { homedir } from 'os';

const run = () => {

  let preloadImage;
  let intro;
  let navToggle;
  const gnav = document.querySelector('.c-gnav_wrap') || null;
  
  
  

  // /*---------------------
  // Page transition
  // ---------------------*/
  const PageTransition = Barba.BaseTransition.extend({
    strat: function(){
      Promise
      .all([this.newContainerLoading, this.hide()])
      .then(this.finish.bind(this))
    }, 
    hide: function(){
      window.scrollTo(0, 0);
      this.oldContainer.classList.add('hide');
    },
    finish: function(){
      this.newContainer.classList.add('show');
      this.oldContainer.style.visibility = 'hidden';
      // navToggle.hide();
      this.done();
    }
  });


  // /*---------------------
  // transitionCompleted
  // ---------------------*/
  Barba.Dispatcher.on( 'transitionCompleted', (currentStatus, prevStatus) => {
    AddHover();
    fullHeight();
    rewriteNamespace(currentStatus, prevStatus);
    window.scrollTo(0, 0);
  });

  const rewriteNamespace = (currentStatus, prevStatus) =>{
    if(prevStatus && prevStatus.namespace){
      if( !currentStatus || ( currentStatus.namespace && currentStatus.namespace !== prevStatus.namespace )){
        document.body.classList.remove(prevStatus.namespace);
      }
    }
    if ( currentStatus && currentStatus.namespace ) {
      document.body.classList.add( currentStatus.namespace );
    }
  }




  // /*---------------------
  // home page
  // ---------------------*/
  const Home = Barba.BaseView.extend({
    namespace:'index',
    onEnterCompleted: function(){
      homeUtil();
    }
  });

  // /*---------------------
  // about
  // ---------------------*/
  const About = Barba.BaseView.extend({
    namespace:'about',
    onEnterCompleted: function(){
      const gmap = document.getElementById('js-gmap') || null;
      const slider = document.querySelector('.js-slider') || null;
      if(gmap){  gMapDisplay(gmap, 18); }
      if(slider){ new SlideShow(slider); }
    }
  });

  // /*---------------------
  // access
  // ---------------------*/
  const Access = Barba.BaseView.extend({
    namespace:'access',
    onEnterCompleted: function(){
      const gmap = document.getElementById('js-gmap') || null;
      if(gmap){  gMapDisplay(gmap, 18); }
    }
  });

  // /*---------------------
  // contact
  // ---------------------*/
  const Contact = Barba.BaseView.extend({
    namespace:'contact',
    onEnterCompleted: function(){
      // var script = document.createElement('script');
      // script.id = 'mfpjs';
      // script.charset = "UTF-8";
      // script.src = "http://berryz.co.jp/cliant//bellju-mita/mailformpro/mailformpro.cgi";
      // if(script.src){
      //   var mfp = new MailformPro();
      // }
      var mfp = new MailformPro();
      console.log(mfp);
    }
  });


  // ---------------------------------
  // init
  // ---------------------------------
  const init = ()=>{
    Home.init();
    About.init();
    Access.init();
    Contact.init();

    ToggleNav('js-gnav', '.site-gnav_item');
    
    Barba.Pjax.start();
    Barba.Prefetch.init();
    BarbaBasic();
    

    // Barba.Pjax.getTransition = ()=>{
    //   return PageTransition;
    // }

    
    
  }
  init();




  

  









  };

document.addEventListener('DOMContentLoaded', run, false);
