export default function AddHover(){
    let hasTapEvent = ('ontouchstart' in window);
    let links = document.querySelectorAll('a');

    const addHoverClass = (e) => {
        e.target.classList.add('hover');
    };
    const removeHoverClass = (e) => {
        e.target.classList.remove('hover');
    };

    for(var i = 0; i < links.length; i++){
        if(hasTapEvent){
            links[i].addEventListener('touchstart', addHoverClass, false);
            links[i].addEventListener('touchend', removeHoverClass, false);
        }else{
            links[i].addEventListener('mouseenter', addHoverClass, false);
            links[i].addEventListener('mouseleave', removeHoverClass, false);
        }
    }
}
