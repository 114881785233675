export default function ToggleNav(toggleTarget, gnavItem){

    let toggle = document.getElementById(toggleTarget) || null;
    let navItems = document.querySelectorAll(gnavItem) || null;

    for(let i = 0; i< navItems.length; i++){
      navItems[i].addEventListener('click', navToggle, false);
    }
    toggle.addEventListener('click', navToggle, false);


    function navToggle(){
      //thisはtoggletarget自身
        if(!this.classList.contains('is-active')){
            this.classList.add('is-active');
            this.classList.add('is-active');
            document.body.classList.add('nav-open');
        }else{
            this.classList.remove('is-active');
            document.body.classList.remove('nav-open');
        }
    }



}
