const throttle = require('throttle-debounce/throttle');

export default function fullHeight(){
  let elms = document.querySelectorAll('.js-fullH') || null;

  const resize = ()=>{
    for(let i = 0; i < elms.length; i++){
      elms[i].style.height = window.innerHeight + 'px';
    }
  };

  const events = ()=>{
    window.addEventListener('resize', throttle(200, ()=>{
      resize();
    }), false);
  };

  const run = ()=>{
    if(elms){
      resize();
      events();
    }
  };

  run();
}
